<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/register.jpg"
                alt="register"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="px-8 pt-8 register-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{ $t('CreateAccount') }} </h4>
                  <p> {{ $t('FillTheForm') }} </p>
                </div>
                <!-- <vs-tab label="JWT"> -->
                <RegisterJWT></RegisterJWT>
                <!-- </vs-tab> -->

                <!-- <vs-tab label="Firebase">
                                    <register-firebase></register-firebase>
                                  </vs-tab>

                                  <vs-tab label="Auth0">
                                    <register-auth0></register-auth0>
                                  </vs-tab> -->
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import RegisterJWT from "./RegisterJWT.vue";
// import RegisterFirebase from './RegisterFirebase.vue'
// import RegisterAuth0 from './RegisterAuth0.vue'

export default {
  components: {
    RegisterJWT
    // RegisterFirebase,
    // RegisterAuth0
  }
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 597px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
